
const minuteFactor = 60000;

export default {
    set: function(key, value, expires){
        sessionStorage.setItem(key, JSON.stringify(value));

        /*setTimeout(function(){ //todo : save expires in state so it can be checked against actual time in the state getter, to remove it there. current method doesn't work after page refresh
            sessionStorage.removeItem(key);
        }, expires * minuteFactor);*/
    },

    get: function(key){
        return JSON.parse(sessionStorage.getItem(key));
    },

    remove: function(key){
        sessionStorage.removeItem(key);
    },

    clear: function(){
        sessionStorage.clear();
    }

}