<template>
    <div class="user-menu-wrapper">
    <div class="user-menu-container" >
        <div v-on:click="closeModal" class="close-btn">
            <i class="fa-solid fa-times-circle"></i>
        </div>
        <div class="user-display">{{ user.displayName }}</div>
        <div class="user-info">
            <div class="info-line">
                <div class="info-label">Tier : </div>
                <div class="info-value">{{ user.tier }}</div>
            </div>
            <div class="info-line">
                <div class="info-label">Permissions : </div>
                <div class="info-value">{{ user.userType }}</div>
            </div>
        </div>
        <div class="connect-wallet-button">Connect Wallet</div>
        <div v-on:click="logout" class="logout-button">Log Out</div>
    </div>
    </div>
</template>

<script>


export default {
      name: "UserMenu",
      data() {
        return {
         loggingIn: true,
         modalState:false,
         displayName: "",
         logInErrorMessage : "",
         signUpErrorMessage: "",
         
        };
      },
      computed: {
        user: function(){
            return this.$store.state.user;
        }
      },
      methods:{
        closeModal(){
            this.$emit('closeUserMenu');
        },
        logout(){
            this.$emit('logout');
        }


      }, 
      mounted: function(){
        
      }
}
</script>

<style scoped>
    .user-menu-container{
        background-color:white;
        width: 250px;
        max-height:300px;
        color:black;
        display:flex;
        flex-direction: column;
        text-shadow: none;
        padding:1em;
        cursor:default;
        gap: 1em;
        box-sizing:border-box;
    }
    .close-btn{
        position:absolute;
        right: .5em;
        top: .5em;
        cursor: pointer;
        display: none;
    }
    .user-display{
        font-size: 1.2em;
        font-weight: bold;
        display:flex;
        align-items: flex-start;

    }

    .connect-wallet-button{
        margin-top:auto;
        padding: .5em;
        font-weight:bold;
        display:flex;
        justify-content: center;
        align-items: center;
        background-color:rgb(18, 39, 230);;
        color: white;
        border-radius: .25em;
        cursor:pointer;
        transition: all 200ms ease-in-out;
    }
    .connect-wallet-button:hover{
        background-color: #6161f1;
        transition: all 200ms ease-in-out;
    }

    .logout-button{
        padding: .5em;
        font-weight:bold;
        display:flex;
        justify-content: center;
        align-items: center;
        background-color:black;
        color:white;
        border-radius: .25em;
        cursor:pointer;
        transition: all 200ms ease-in-out;
    }

    .logout-button:hover{
        background-color: #333;
        transition: all 200ms ease-in-out;
    }

    .user-info{
        display:flex;
        flex-direction: column;
    }

    .info-line{
        display:flex;
        flex-direction: row;
        gap: .3em;
    }

    .info-label{
        font-weight:bold;
    }

    .info-value{

    }
    @media only screen and (max-width: 768px) {
    .user-menu-container{
        height:100%;
        width:100%;
        max-height:100%;
        max-width:100%;
        
    }

    .close-btn{
        position:absolute;
        right: .5em;
        top: .5em;
        cursor: pointer;
        display: block;
        font-size: 1.5em;
    }

  }
</style>